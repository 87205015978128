.apex-navbar {
    background-color: rgba(255, 255, 255, .15);
    backdrop-filter: blur(5px);
    z-index: 99999;
    text-decoration: none !important;
}

.apex-navbar * {
    text-decoration: none !important;
}

.apex-navbar,
.apex-navbar .navbar-brand,
.apex-navbar .nav-link {
    color: #fff !important;
    text-decoration: none !important;
    /* Use !important cautiously */
}

.apex-navbar .dropdown-menu {
    border-radius: 0;
}

.navbar-logo {
    background: var(--red);
    position: absolute;
    height: 80px;
    top: 0;
    width: 54px;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
    padding: 15px 7px 0px 7px;
}

.navbarbrand-title {
    margin-left: 64px;
    text-decoration: none;
    color: #fff;
}